import Raicon from 'raicon';
import initDoughnutChart from '@/layouts/application/utils/charts/initDoughnutChart';

import { NET_WEALTH, LIABILITY } from '@/layouts/application/utils/charts/chartColors';
import AssetPropertyForm from './assetPropertyForm';
import AssetCarForm from './assetCarForm';
import AssetInvestmentForm from './assetInvestmentForm';
import LoanForm from './loanForm';
import AssetCpfForm from './assetCpfForm';
import AssetEndowmentForm from './assetEndowmentForm';
import { displayOtherOptionInputField, handleWhenSelectOthersValue } from '../shared/toggleHandler';

import { add10Referees } from '../add10Referees';
import NetwealthChart from './netwealthChart';
import CpfChart from './cpfChart';
import GiftingCalculatorClientForm from '../clients/giftingCalculatorForm';
import { bulkDeleteItem } from '../shared/bulkDeleteItem';
import { handleHideSignSgd } from '../shared/handleHideSignSgd';
import OverlayCanvas from '../../shared/overlayCanvas';

class ConsultantNetwealthsController {
  constructor() {
    this.netwealthChartDetailContainer = '#netwealth-chart-details-container';
    this.cpfChartDetailContainer = '#cpf-chart-details-container';
    this.currencySelector = '#currency-select';
    this.propertyCurrency = '.property-currency';
  }

  index() {
    this.isRenderCpfChart = false;
    this.isRenderNetworthChart = false;
    this.drawNetwealthChart();
    this.addTriggerOpenModalEvents();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    this.initNetwealthChartDetail();
    this.initCpfChartDetail();
    this.triggerActiveCurrentContentTab();
    this.setupGiftingCalculatorForm();
    this.setupOverlayCanvas();
    handleHideSignSgd(this.currencySelector, this.propertyCurrency);
    bulkDeleteItem('#bulk-delete-asset', '.asset-checkbox');
    bulkDeleteItem('#bulk-delete-asset-investment', '.asset-investment-checkbox');
    bulkDeleteItem('#bulk-delete-other-asset', '.other-asset-checkbox');
    bulkDeleteItem('#bulk-delete-liability', '.liability-checkbox');
  }

  setupGiftingCalculatorForm() {
    new GiftingCalculatorClientForm().setup();
  }

  setupOverlayCanvas() {
    new OverlayCanvas().setup();
  }

  drawNetwealthChart() {
    const $chartElement = $('#netwealth-chart');
    const chartData = $chartElement.parent().data('calculation');

    if ($chartElement.length > 0) {
      initDoughnutChart($chartElement, chartData, [NET_WEALTH, LIABILITY]);
    }
  }

  initNetwealthChartDetail() {
    const $netwealthChartDetailContainer = $(this.netwealthChartDetailContainer);

    $('body').on('click', '#networth-view-button', () => {
      if (this.isRenderNetworthChart) return;
      new NetwealthChart($netwealthChartDetailContainer).setup();

      this.isRenderNetworthChart = true;
    });
  }

  initCpfChartDetail() {
    const $cpfChartDetailContainer = $(this.cpfChartDetailContainer);

    $('body').on('click', '#cpf-view-button', () => {
      if (this.isRenderCpfChart) return;
      new CpfChart($cpfChartDetailContainer).setup();
      this.isRenderCpfChart = true;
    });
  }

  addTriggerOpenModalEvents() {
    $('body').on('modal:afterSetContent', '#open-property-asset, #asset-property-form', () => {
      $(this.currencySelector).trigger('change');
      this.togglePropertyMortgageEvent();
      new AssetPropertyForm().setup();
    });

    $('body').on('modal:afterSetContent', '#open-car-asset, #asset-car-form', () => {
      $(this.currencySelector).trigger('change');
      this.toggleCarLoanEvent();
      new AssetCarForm().setup();
    });

    $('body').on('modal:afterSetContent', '#open-investment, #asset-investment-form, #back-link', () => {
      $(this.currencySelector).trigger('change');
      this.toggleInvestmentRecurringEvent();
      new AssetInvestmentForm().setup();
    });

    $('body').on('modal:afterSetContent', '#open-loan-liability, #loan-liability-form', () => {
      $(this.currencySelector).trigger('change');
      new LoanForm().setup();
    });

    $('body').on('modal:afterSetContent', '#open-cpf-asset, #asset-cpf-account-form', () => {
      $(this.currencySelector).trigger('change');
      new AssetCpfForm().setup();
    });

    $('body').on('modal:afterSetContent', '#open-endowment-asset, #asset-endowment-form, #back-link', () => {
      $(this.currencySelector).trigger('change');
      this.toggleDreamPlanningEvent();
      new AssetEndowmentForm().setup();
    });

    $('body').on('modal:afterSetContent', `${'#new-saving-assets'}, ${'#asset-saving-form'}`, () => {
      $(this.currencySelector).trigger('change');
      this.toggleOtherOptionInputField($('#bank-select'), $('.bank-others-option'));
    });

    $('body').on('modal:afterSetContent', `${'#new-fixed-deposit-asset'}, ${'#asset-fixed-deposit-form'}`, () => {
      $(this.currencySelector).trigger('change');
      this.toggleOtherOptionInputField($('#bank-select'), $('.bank-others-option'));
    });

    $('body').on('modal:afterSetContent', `${'#new-srs-account-asset'}, ${'#asset-srs-account-form'}`, () => {
      $(this.currencySelector).trigger('change');
      this.toggleOtherOptionInputField($('#bank-select'), $('.bank-others-option'));
    });

    $('body').on('modal:afterSetContent', '#open-asset-other-form', () => {
      $(this.currencySelector).trigger('change');
    });

    $('.open-edit-asset').on('modal:afterSetContent', () => {
      $(this.currencySelector).trigger('change');
      this.toggleOtherOptionInputField($('#bank-select'), $('.bank-others-option'));
    });

    $('body').on('modal:afterSetContent', `${'#networth-view-detail-btn'}`, () => {
      $('.total-cash-surplus-dropdown').on('click', () => {
        $('.total-savings-group.show').collapse('hide');
      });
    });
  }

  togglePropertyMortgageEvent() {
    const $checkbox = $('#property-mortgage-checkbox');
    const $mortgageForm = $('.property-mortgage-form');

    this.toggleElementByCheckboxEvent($checkbox, $mortgageForm);
  }

  toggleCarLoanEvent() {
    const $checkbox = $('#car-loan-checkbox');
    const $loanForm = $('.car-loan-form');

    this.toggleElementByCheckboxEvent($checkbox, $loanForm);
  }

  toggleInvestmentRecurringEvent() {
    const $checkbox = $('#investment-recurring-checkbox');
    const $recurringForm = $('.recurring-investment-form');

    this.toggleElementByCheckboxEvent($checkbox, $recurringForm);
  }

  toggleDreamPlanningEvent() {
    const $checkbox = $('#dream-planing-checkbox');
    const $recurringForm = $('.payout-option-form');

    this.toggleElementByCheckboxEvent($checkbox, $recurringForm);
  }

  toggleElementByCheckboxEvent($checkbox, $form) {
    if ($checkbox.is(':checked')) {
      $form.show();
    } else {
      $form.hide();
    }

    $checkbox.on('change', function () {
      if (this.checked) {
        $form.show();
      } else {
        $form.hide();
      }
    });
  }

  toggleOtherOptionInputField(selectInput, optionInput) {
    displayOtherOptionInputField(selectInput, optionInput);
    handleWhenSelectOthersValue(selectInput, optionInput);
  }

  triggerActiveCurrentContentTab() {
    $('a[href="#networth-index-show"]').hide();
    $('a[href="#networth-cpf-account"]').hide();

    $('a[data-toggle="pill"]').on('shown.bs.tab', function () {
      const activeId = $('#overview-content .active')[0].id;
      const unActiveId = $('#overview-content').children('div').not('.active')[0].id;

      $(`a[href="#${activeId}"`).hide();
      $(`a[href="#${unActiveId}"]`).show();

      if (activeId.includes('networth-chart-show')) {
        $('a[href="#networth-cpf-account"]').show();
        $('#netwealth-content').hide();
      } else if (activeId.includes('networth-cpf-account')) {
        $('#netwealth-content').hide();
      } else {
        $('a[href="#networth-cpf-account"]').hide().removeClass('active');
        $('#netwealth-content').show();
      }
    });
  }
}

Raicon.register('consultant/netwealths', ConsultantNetwealthsController);
